<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <b-row align-v="end">
      <b-col>
        <validation-observer
          :ref="`${form.id}-form`"
          v-if="canEdit"
        >
          <div class="exam-request">
            <multiselect
              v-model="fields.exame"
              id="exames"
              :options="options"
              :option-height="40"
              :showLabels="false"
              :searchable="true"
              :allowEmpty="false"
              :showNoResults="true"
              placeholder="Selecionar exame"
              @select="addItem"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noResult" slot-scope="props">
                <li @click="addCustomItem(props.search)">
                  <div class="multiselect__option custom-item">
                    <Plus class="icon" />
                    Adicionar solicitação de exame "{{ props.search }}"
                  </div>
                </li>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
            </multiselect>
            <!-- <div class="custom-input" v-else>
              <b-button variant="link" @click="setCustom(false)">
                Selecionar um item da lista
              </b-button>
              <input
                autocomplete="off"
                v-model="fields.exame"
                :readonly="!canEdit"
                type="text"
                class="form-control"
                placeholder=""
                :class="{ error: touched && errors.length > 0 && 1 === 2 }"
              />
            </div> -->
            <b-button 
              class="package-button" 
              variant="primary" 
              @click="$bvModal.show('exam-package-request-modal')"
            >
              Pacote de exames
            </b-button>
            
            <!-- <b-form-textarea
              v-model="fields.comentario"
              @inputTextArea="
                () => {
                  fields.updatedTemplate = true
                }
              "
              size="sm"
              rows="2"
              placeholder="insira um comentário"
              no-resize
            /> -->
            <!-- <div class="edit-area">
              <div class="btn-container">
                <b-button
                  v-show="
                    fields.template &&
                    fields.template.id &&
                    fields.template.professional_id
                  "
                  variant="link"
                  block
                  class="h-38 button remove"
                  @click="deleteModal"
                >
                  Excluir comentário
                </b-button>
                <b-button
                  v-show="
                    fields.template &&
                    fields.template.professional_id &&
                    fields.updatedTemplate
                  "
                  variant="outline-primary"
                  block
                  class="h-38 button"
                  :disabled="!fields.template || !fields.comentario"
                  @click="saveTemplate"
                >
                  {{
                    fields.template && fields.template.id
                      ? 'Salvar alterações no modelo do comentário'
                      : 'Salvar como novo comentário personalizado'
                  }}
                </b-button>
                <b-button
                  variant="primary"
                  block
                  class="h-38 button"
                  :disabled="invalid"
                  @click="fields.editing ? changeItem(fields.index) : addItem()"
                >
                  {{ fields.editing ? 'Salvar alterações' : 'Adicionar' }}
                </b-button>
              </div>
            </div> -->
          </div>

        </validation-observer>
        <!-- <b-button
          variant="primary"
          block
          class="h-38 button"
          :disabled="invalid"
          @click="addItem"
        >
          Adicionar
        </b-button> -->

        <div class="package-card" v-for="(_package, packageIndex) in form.value" :key="packageIndex">
          <div class="package-header">
            <div>{{ _package.name || 'Exames avulsos' }}</div>
            <a class="delete-icon" @click="removePackage(packageIndex)">Remover pacote</a>
          </div>
          <table class="table-eyecare">
            <thead>
              <tr>
                <th width="25%">Exame</th>
                <th>Lateralidade</th>
                <th width="40%">Comentário</th>
                <th width="0"></th>
              </tr>
            </thead>
            <tbody>

                  <tr v-for="(exam, examIndex) in _package.exams" :key="examIndex">
                    <td>
                      <p>{{ exam.exame }}</p>
                    </td>

                    <td>
                      <div class="form-group row mt-2">
                        <multiselect
                          v-model="exam.examLateralityModel"
                          id="laterality"
                          :options="[
                            'Ambos os Olhos',
                            'Olho Esquerdo',
                            'Olho Direito'
                          ]"
                          :option-height="40"
                          :showLabels="false"
                          :allowEmpty="true"
                          placeholder="Selecionar Lateralidade"
                          @select="updateExam(packageIndex, examIndex, 'examLateralityModel', $event)"
                          @remove="updateExam(packageIndex, examIndex, 'examLateralityModel', null)"
                          :disabled="!canEdit"
                        >
                          <template slot="caret">
                            <div class="chevron">
                              <ChevronDown />
                            </div>
                          </template>
                        </multiselect>
                      </div>
                    </td>

                    <td>
                      <b-form-input
                        :disabled="!canEdit"
                        v-model="exam.comentario"
                        @blur="updateMedicalRecord(form)"
                      />
                    </td>
                    <td class="actions d-flex flex-row justify-content-end" v-if="canEdit">
                      <Icon tooltip="Remover Exame" class="text-right">
                        <Delete class="icon" @click="removeItem(packageIndex, examIndex)" />
                      </Icon>
                    </td>
                  </tr>
              
            </tbody>
          </table>
          <br/>
          <b-row>
            <b-col class="form-group">
              <label>Observação</label>
              <TextArea
                id="observacao"
                rows="1"
                v-model="_package.observation"
                :readonly="!canEdit"
                @blur="updateMedicalRecord(form)"
                placeholder="Observação"
              />
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    
    <ExamPackageRequestModal />
    
  </FormWrapper>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    Delete: () => import('@/assets/icons/delete.svg'),
    // Edit: () => import('@/assets/icons/edit.svg'),
    Icon: () => import('@/components/General/Icon'),
    TextArea: () => import('@/components/General/TextArea'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Plus: () => import('@/assets/icons/plus.svg'),
    ExamPackageRequestModal: () => import('@/components/Attendance/Forms/Modules/ExamRequest/ExamPackageRequestModal')
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.solicitacaoDeExames,
      /**
       * @param {Array} eyeExamsList
       * @returns {Array}
       */
      eyeExamsList: state => state.attendance.eyeExams.list
    })
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      templates: [],
      options: [],
      examesOftalmologicos: [],
      customTemplateName: '',
      customTemplateActive: false,
      fields: this.getDefaultFields()
    }
  },
  async mounted() {
    try {
      if (this.eyeExamsList.length === 0) {
        await this.getEyeExamsList().then(res => {
          this.populateLists()
        })
      } else {
        this.populateLists()
      }
    } catch (error) {
      this.$toast.error(error.message)
    }
  },
  methods: {
    ...mapActions('attendance/form', [
      'updateMedicalRecord',
      'destroyMedicalRecord'
    ]),
    ...mapActions('attendance/form/solicitacaoDeExames', [
      'handleFields',
      'handleProps'
    ]),
    ...mapActions('attendance/eyeExams', ['getEyeExamsList']),
    addItem() {
      this.customTemplateActive = false
      const packages = [...this.form.value]

      if (!packages.some(el => !el.name)) {
        packages.unshift({ exams:[], observation: null })
      }

      packages[0].exams.push({...this.fields})
      this.handleProps({ key: 'value', value: packages })
      this.updateMedicalRecord(this.form)
      this.clearFieldTemplate()
      this.fields = this.getDefaultFields()
    },
    addCustomItem(exameName) {
      const packages = [...this.form.value]

      if (!packages.some(el => !el.name)) {
        packages.unshift({ exams:[], observation: null })
      }

      packages[0].exams.push({
        ...this.getDefaultFields(),
        custom: true,
        exame: exameName
      })
      
      this.handleProps({ key: 'value', value: packages })
      this.updateMedicalRecord(this.form)
      this.clearFieldTemplate()
      this.fields = this.getDefaultFields()
    },
    updateExam(packageIndex, examIndex, key, value) {
      const packages = [...this.form.value]
      packages[packageIndex].exams[examIndex][key] = value
      this.handleProps({ key: 'value', value: packages })
      this.updateMedicalRecord(this.form)
    },
    removePackage(packageIndex) {
      const packages = [...this.form.value]
      packages.splice(packageIndex, 1)
      this.handleProps({ key: 'value', value: packages })
      this.updateMedicalRecord(this.form)
    },
    removeItem(packageIndex, examIndex) {
      const packages = [...this.form.value]
      packages[packageIndex].exams.splice(examIndex, 1)
      this.handleProps({ key: 'value', value: packages })
      this.updateMedicalRecord(this.form)
    },








    updateForm(key, value) {
      this.handleFields({ key, value })
    },

    populateLists() {
      this.eyeExamsList.forEach(item => {
        this.options.push(item.value)
        this.examesOftalmologicos.push(item)
      })
    },

    getDefaultFields() {
      return {
        custom: false,
        exame: null,
        comentario: null,
        template: null,
        examLaterality: null,
        examLateralityModel: null,
        updatedTemplate: false
      }
    },
    setCustom(value, searchTerm) {
      this.fields.custom = value
      this.fields.exame = value ? searchTerm : null
    },

    

    clearEditable() {
      setTimeout(() => {
        const exams = [...this.form.fields.exams]
        exams.map(v => v.editing && delete v.editing)
        this.handleFields({ key: 'exams', value: exams })
        this.fields = this.getDefaultFields()
      }, 500)
    },
    async getExamRequestTemplates(exam) {
      try {
        const filters = {
          exam: exam,
          professional_id: this.user.id
        }
        const response = await this.api.getExamRequestTemplates(filters)
        this.templates = response.data
      } catch (err) {
        this.$toast.error('Não foi possível obter os comentários deste exame!')
      }
    },

    async deleteTemplate(template) {
      const loading = this.$loading.show()
      try {
        await this.api.deleteExamRequestTemplates(template.id)
        await this.updateTemplatesoByFieldExam()
        this.fields = this.getDefaultFields()
        this.$toast.success('Comentário excluído com sucesso!')
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },
    async deleteModal() {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">
          Você tem certeza que deseja remover o comentário personalizado 
          <span>${this.fields.template.name}</span>?
          <p>Não será possível reverter essa ação.</p>
          </div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(async res => {
        if (res.isConfirmed) {
          await this.deleteTemplate(this.fields.template)
        }
      })
    },
    async updateTemplatesoByFieldExam() {
      const exam = this.examesOftalmologicos.find(el => {
        return el.value === this.fields.exame
      })

      await this.getExamRequestTemplates(exam)
    },

    validateTemplate() {
      let isValid = true

      if (!this.fields.template.name) {
        isValid = false
        this.$toast.warning('Por favor, preencha o nome do comentário')
      }

      if (!this.fields.comentario) {
        isValid = false
        this.$toast.warning('Por favor, preencha o texto do comentário')
      }

      return isValid
    },
    clearFieldTemplate() {
      this.fields.template = null
      this.fields.comentario = null
    },
    async saveTemplate() {
      if (!this.validateTemplate()) return

      const loading = this.$loading.show()
      try {
        this.fields.template.template = this.fields.comentario
        if (this.fields.template.id) {
          await this.api.updateExamRequestTemplates(
            this.fields.template.id,
            this.fields.template
          )
          this.$toast.success('Comentário atualizado com sucesso!')
        } else {
          const response = await this.api.createExamRequestTemplates(
            this.fields.template
          )
          this.fields.template = response.data
          this.$toast.success('Comentário adicionado com sucesso!')
        }
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        this.fields.updatedTemplate = false
        loading.hide()
      }
    },
    hideForm() {
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.value.forEach((key, index) => {
        this.removeItem(index)
      })
    }
  },
  watch: {
    'fields.exame': async function (value) {
      this.customTemplateActive = false
      const exame = this.examesOftalmologicos.find(el => {
        return el.value === value
      })

      await this.getExamRequestTemplates(exame)
    },
    'fields.template': function (value) {
      if (value && value.template) {
        this.fields.comentario = value.template
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.exam-request {
  display: flex;
  flex-direction: row;
  gap: 10px;

  .package-button {
    white-space: nowrap;
  }
  .new-template {
    font-weight: 700;
    color: var(--blue-500);
  }

  .icon {
    fill: var(--blue-500);
    width: 26px;
    margin-right: 8px;
  }

  .multiselect {
    border: 1px solid #c6ceeb !important;
    border-radius: 8px;
  }
}

.table-eyecare {
  margin-top: 10px;
  padding: 16px;
  tbody td {
    border-top: 0;
  }
  .multiselect {
    border: 1px solid #c6ceeb !important;
    border-radius: 8px;
  }
}

.multiselect {
  .custom-item {
    color: var(--blue-500);
    display: flex;
    align-items: center;
    font-weight: 600;

    .icon {
      width: 24px;
      height: 24px;
      fill: var(--blue-500);
      margin-right: 12px;
    }
  }

  .multiselect__single {
    @media (min-height: 1600) {
      font-size: 1.6vh;
    }

    @media (max-height: 1600) {
      font-size: 1vw;
    }
  }
}

.button {
  height: 38px;
  margin-bottom: 24px;
}

.edit-area {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: end;

  .btn-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: end;
    height: 45px;

    button {
      width: 150px;
      margin-left: 30px;
    }

    .remove {
      margin-top: 0.5rem;
      color: var(--states-error);
    }

    button:nth-child(2) {
      width: 350px;
    }
  }
}

.actions {
  .icon-container {
    margin-top: 0.8rem !important;
  }
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    cursor: pointer;
  }
  .delete:disabled {
    .icon {
      cursor: not-allowed;
    }
  }
}

.package-card {
  border: 1px dashed var(--blue-500);
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1rem;
}

.package-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
}

.delete-icon {
  color: var(--states-error);
  opacity: 0.8;
  cursor: pointer;
  font-size: 0.8rem;
}
</style>
